import { Component, Vue } from 'vue-property-decorator';
import Oidc from 'oidc-client';

Oidc.Log.logger = console;
Oidc.Log.level = Oidc.Log.DEBUG;

@Component({
    name: 'Callback',
})
export default class SigninCallback extends Vue {
    public render(): any { return null; }

    public beforeCreate(): void {
        try { new Oidc.UserManager({}).signinPopupCallback(); } catch (e) { Oidc.Log.logger.error(e); }
    }
}
